<template>
  <div class="d-flex flex-column gap-2">
    <div>
      <v-card
        elevation="0"
        class="d-flex align-center gap-x-4 flex-wrap rounded-lg pa-2"
      >
        <div class="d-flex gap-3 align-center flex-grow-1">
          <div>
            <v-skeleton-loader
              v-if="!party"
              height="60px"
              :width="`${60 * (16 / 9)}px`"
              class="rounded-lg"
              type="image"
            />
            <v-img
              v-else
              class="rounded-lg"
              :aspect-ratio="16 / 9"
              :src="party?.cover"
              height="60px"
              :width="`${60 * (16 / 9)}px`"
            />
          </div>

          <div>
            <v-skeleton-loader
              v-if="!party"
              width="100px"
              height="12px"
              class="rounded-lg mb-1"
              type="card"
            />
            <p v-else class="mb-0 text-overline lh-1">
              {{ party?.date | date("DD/MM/YY - HH:mm") }}
            </p>
            <v-skeleton-loader
              v-if="!party"
              width="180px"
              height="24px"
              class="rounded-lg"
              type="card"
            />
            <h6 v-else class="mb-0">{{ party?.name }}</h6>
          </div>
        </div>
        <div class="d-flex flex-wrap flex-grow-1 flex-shrink-1 gap-1">
          <v-btn
            class="flex-grow-1"
            color="primary"
            text
            :to="{
              name: 'admin.entrance.sessions',
              params: { partyId: partyId },
            }"
          >
            Sessões
          </v-btn>
          <v-btn
            class="flex-grow-1"
            color="primary"
            text
            :to="`/admin/organization/parties/${partyId}/management#reports`"
          >
            Relatórios
          </v-btn>
          <v-btn
            class="flex-grow-1"
            color="primary"
            text
            :to="{
              name: 'admin.entrance.scan',
              params: { partyId: partyId },
            }"
          >
            Scanner
          </v-btn>
        </div>
      </v-card>

      <div class="d-flex flex-wrap justify-space-between align-center mx-1">
        <v-chip-group
          v-if="!!periods && periods.length"
          v-model="selectedPeriod"
          center-active
          mandatory
          show-arrows
        >
          <v-chip
            :value="null"
            :disabled="loading"
            filter
            small
            class="my-0"
            color="primary"
          >
            Todos
          </v-chip>
          <v-chip
            v-for="period in periods"
            :key="period.id"
            :value="period.id"
            :disabled="loading"
            filter
            small
            class="my-0"
            color="primary"
          >
            {{ period.name }}
          </v-chip>
        </v-chip-group>
        <div>
          <v-btn
            left
            icon
            small
            @click="getEntrance"
            :loading="loading"
            :disabled="refreshDisabled"
          >
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
          <small class="">
            Última atualização: {{ lastUpdate | date("HH:mm:ss") }}
          </small>
        </div>
      </div>
    </div>
    <EntriesSummary
      v-if="party"
      :party="party"
      :loading="loading"
      :entries="entries"
      :duration="duration"
      :tickets="filteredTickets"
      :ticketsUsed="ticketsUsed"
      :selectedPeriod="selectedPeriodObj"
    />

    <EntriesByGroup
      v-if="party"
      :party="party"
      :loading="loading"
      :entries="entries"
      :tickets="filteredTickets"
      :ticketsUsed="ticketsUsed"
      :selectedPeriod="selectedPeriodObj"
    />

    <EntriesEvolution
      v-if="party"
      :party="party"
      class="mx-1"
      :loading="loading"
      :entries="entries"
      :duration="duration"
      :tickets="filteredTickets"
      :ticketsUsed="ticketsUsed"
      :selectedPeriod="selectedPeriodObj"
    />

    <entries-table
      v-if="party"
      class="mx-1"
      :party="party"
      :partyId="partyId"
      :tickets="filteredTickets"
      :ticketsUsed="ticketsUsed"
      :selectedPeriod="selectedPeriodObj"
    />
  </div>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";
import EntriesTable from "../../../components/admin/party/entrance/EntriesTable.vue";
import EntriesSummary from "../../../components/admin/party/entrance/EntriesSummary.vue";
import EntriesEvolution from "../../../components/admin/party/entrance/EntriesEvolution.vue";
import EntriesByGroup from "../../../components/admin/party/entrance/EntriesByGroup.vue";

export default {
  components: {
    EntriesTable,
    EntriesSummary,
    EntriesEvolution,
    EntriesByGroup,
  },
  metaInfo: { title: "Gerenciar Entrada" },
  data: () => ({
    loading: false,
    lastUpdate: null,
    party: null,
    tickets: null,
    interval: null,
    selectedPeriod: null,
    refreshDisabled: false,
    durationConfig: {
      minimumInterval: 10,
      comparisonsRequired: 1,
    },
  }),
  methods: {
    ...mapActions("organization", ["partyById"]),
    async getParty() {
      this.party = await this.partyById(this.partyId);
      this.$root.$emit("setImgBg", this.party.cover);
    },
    async getEntrance() {
      try {
        this.loading = true;
        this.refreshDisabled = true;
        var response = await ORGANIZATION.party.entrance.ticket.getAll(
          this.selectedOrganization.id,
          this.partyId
        );
        this.tickets = response.tickets;
        this.lastUpdate = new Date();
        this.enableRefresh(10000);
      } catch (error) {
        this.enableRefresh(5000);
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    enableRefresh(ms) {
      this.refreshDisabled = true;
      setTimeout(() => {
        this.refreshDisabled = false;
      }, ms);
    },

    scan() {
      this.$router.push({
        name: "admin.entrance.scan",
        params: { partyId: this.partyId },
      });
    },
    filterEntries(entries) {
      const { minimumInterval, comparisonsRequired } = this.durationConfig;
      const validEntries = [];
      let currentIndex = 0;

      const minimumIntervalMs = minimumInterval * 60 * 1000;

      while (currentIndex < entries.length) {
        let isValidInterval = false;
        const currentTime = new Date(entries[currentIndex].createdAt).getTime();

        for (
          let j = 1;
          j <= comparisonsRequired && currentIndex + j < entries.length;
          j++
        ) {
          const comparisonTime = new Date(
            entries[currentIndex + j].createdAt
          ).getTime();

          if (comparisonTime - currentTime <= minimumIntervalMs) {
            isValidInterval = true;
            break;
          }
        }

        if (isValidInterval) {
          validEntries.push(entries[currentIndex]);
        }

        currentIndex++;
      }

      return validEntries;
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
    partyId() {
      return this.$route.params.partyId;
    },
    filteredTickets() {
      if (!this.tickets) return false;
      var periodsTicketGroups = this.selectedPeriodObj
        ? this.selectedPeriodObj.TicketGroup.map((tg) => tg.id)
        : false;
      return this.tickets.filter((t) => {
        if (!periodsTicketGroups) return true;
        const tgId = t.TicketBlock.TicketGroup.id;
        return periodsTicketGroups.includes(tgId);
      });
    },
    ticketsUsed() {
      if (!this.filteredTickets) return [];
      return this.filteredTickets.filter((t) =>
        t.TicketEntry.some(
          (te) =>
            te.approved &&
            (!this.selectedPeriod || te.periodId === this.selectedPeriod)
        )
      );
    },
    entries() {
      if (!this.filteredTickets) return [];
      return this.filteredTickets
        .map(({ TicketEntry }) => TicketEntry)
        .flat()
        .sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
    },
    selectedPeriodObj() {
      if (!this.selectedPeriod) return null;
      return this.periodsMap[this.selectedPeriod] || null;
    },
    periodsMap() {
      return this.periods.reduce((acc, period) => {
        acc[period.id] = period;
        return acc;
      }, {});
    },
    periods() {
      return this.party?.Period || [];
    },
    duration() {
      const normalizedEntries = this.filterEntries(this.entries);
      if (!normalizedEntries.length)
        return {
          start: null,
          end: null,
          duration: null,
        };

      const start = new Date(normalizedEntries[0].createdAt);
      const end = new Date(
        normalizedEntries[normalizedEntries.length - 1].createdAt
      );
      const duration = moment.duration(end - start);
      return {
        start,
        end,
        duration,
      };
    },
  },

  watch: {
    "selectedOrganization.id"() {
      this.$router.push({ name: "admin.entrance" });
    },
  },
  async mounted() {
    await this.getParty();
    this.getEntrance();
    this.interval = setInterval(() => {
      this.getEntrance();
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  destroyed() {
    this.$root.$emit("setImgBg", null);
  },
};
</script>
