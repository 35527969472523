<template>
  <div>
    <v-card :loading="loading" elevation="0" rounded="lg">
      <div class="pa-3">
        <v-data-table
          :headers="headers"
          :items="sessions"
          :loading="loadingInner || loading"
          dense
        >
          <template v-slot:top>
            <div class="d-flex align-center">
              <h6>Sessões</h6>
              <v-spacer />
              <v-btn
                icon
                @click="getSessions"
                :loading="loadingInner"
                :disabled="refreshDisabled"
                class="ml-2"
                small
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                small
                color="primary"
                @click="addSession"
                :disabled="loadingInner"
                class="ml-2"
              >
                <v-icon left>mdi-plus</v-icon>
                Adicionar
              </v-btn>
            </div>
          </template>
          <template v-slot:item.time="{ item }">
            <span
              v-if="!item.startDate && !item.endDate"
              class="text--secondary font-weight-medium"
            >
              Sem restrição
            </span>
            <div v-else>
              <small class="lh-1 d-block">
                {{ item.startDate | date("DD/MM/YY - HH:mm") }} até
              </small>
              <small class="lh-1 d-block">
                {{ item.endDate | date("DD/MM/YY - HH:mm") }}
              </small>
            </div>
          </template>
          <template v-slot:item.TicketBlocks.length="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-chip v-if="item.TicketBlocks.length" v-on="on" label small>
                  {{ item.TicketBlocks.length }} lote{{
                    item.TicketBlocks.length > 1 ? "s" : ""
                  }}
                </v-chip>
              </template>
              <p
                v-for="block in item.TicketBlocks"
                :key="block.id"
                class="mb-0"
              >
                {{ block.TicketGroup.name }} • {{ block.name }}
              </p>
            </v-tooltip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              icon
              small
              @click="qrCode(item)"
              :disabled="loadingInner"
              class="ml-2"
            >
              <v-icon>mdi-qrcode</v-icon>
            </v-btn>
            <v-btn
              icon
              small
              @click="edit(item)"
              :disabled="loadingInner"
              class="ml-2"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <EntranceSessionModal v-if="party" :party="party" @close="getSessions" />
    <EntranceSessionQr :party="party" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";
import EntranceSessionModal from "./modals/EntranceSessionModal.vue";
import EntranceSessionQr from "./modals/EntranceSessionQrCode.vue";

export default {
  components: { EntranceSessionModal, EntranceSessionQr },

  data() {
    return {
      loadingInner: false,
      refreshDisabled: false,
      sessions: [],
      headers: [
        { text: "Nome", value: "name" },
        { text: "Horário", value: "time" },
        { text: "Setores", value: "TicketBlocks.length" },
        { text: "", value: "action" },
      ],
    };
  },
  methods: {
    enableRefresh(ms) {
      this.refreshDisabled = true;
      setTimeout(() => {
        this.refreshDisabled = false;
      }, ms);
    },
    async getSessions() {
      try {
        this.loadingInner = true;
        this.refreshDisabled = true;
        var response = await ORGANIZATION.party.entrance.session.getAll(
          this.party.organizationId,
          this.party.id
        );
        this.sessions = response.sessions;
        this.enableRefresh(60000);
      } catch (error) {
        this.enableRefresh(5000);
        console.error(error);
      } finally {
        this.loadingInner = false;
      }
    },
    addSession() {
      this.$root.$emit("entrance-session-modal");
    },
    edit(session) {
      this.$root.$emit("entrance-session-modal", session);
    },
    qrCode(session) {
      this.$root.$emit("entrance-session-qr", session);
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  watch: {
    party(val, oldVal) {
      if (val && !oldVal) {
        this.getSessions();
      }
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    // await this.getSessions();
  },
};
</script>
