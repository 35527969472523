<template>
  <div class="d-flex flex-wrap gap-2 justify-space-between align-stretch">
    <v-card rounded="lg" class="flex-grow-1" outlined>
      <v-progress-linear
        v-if="!!tickets.length"
        :value="(ticketsUsed.length / tickets.length) * 100"
        height="10"
        class="rounded-t-lg"
      ></v-progress-linear>
      <div class="pa-6 d-flex flex-column align-center justify-center">
        <v-skeleton-loader
          v-if="!tickets"
          height="24px"
          width="70px"
          type="card"
          class="mb-2"
        />
        <h5 v-else class="mb-1 text-center">
          {{ ticketsUsed.length }} /
          {{ tickets.length }}
        </h5>
        <p class="mb-0 text-center lh-2">Ingressos Validados</p>
      </div>
    </v-card>
    <v-card
      class="pa-6 d-flex flex-column align-center justify-center flex-grow-1"
      rounded="lg"
      outlined
    >
      <v-skeleton-loader
        v-if="!tickets"
        height="24px"
        width="70px"
        type="card"
        class="mb-2 rounded"
      />
      <v-tooltip v-else top>
        <template v-slot:activator="{ on }">
          <div>
            <h5 class="mb-1 text-center" v-on="on">
              {{ (ticketsUsed.length / entriesPerMinutes[0]).toFixed(1) }}
            </h5>
          </div>
        </template>
        <p class="mb-0">
          Primeira entrada: {{ entriesPerMinutes[1] | date("DD/M HH:mm") }}
        </p>
        <p class="mb-0">
          Ultima entrada: {{ entriesPerMinutes[2] | date("DD/M HH:mm") }}
        </p>
      </v-tooltip>
      <p class="mb-0 text-center lh-2">Entradas por Minuto</p>
    </v-card>
    <v-card
      v-if="!ended"
      class="pa-6 d-flex flex-column align-center justify-center flex-grow-1"
      rounded="lg"
      outlined
    >
      <v-skeleton-loader
        v-if="!tickets"
        height="24px"
        width="70px"
        type="card"
        class="mb-2 rounded"
      />
      <v-tooltip v-else top>
        <template v-slot:activator="{ on }">
          <h5 class="mb-1 text-center" v-on="on">
            {{ remainTime }}
          </h5>
        </template>
        <p class="mb-0" style="max-width: 550px">
          Tempo necessário para validar todos os ingressos restantes com base na
          média de entrada por minuto.
        </p>
      </v-tooltip>
      <p class="mb-0 text-center lh-2">Tempo Restante</p>
    </v-card>
    <v-card
      v-else
      class="pa-6 d-flex flex-column align-center justify-center flex-grow-1"
      rounded="lg"
      outlined
    >
      <v-skeleton-loader
        v-if="!tickets"
        height="24px"
        width="70px"
        type="card"
        class="mb-2 rounded"
      />
      <v-tooltip v-else top>
        <template v-slot:activator="{ on }">
          <h5 class="mb-1 text-center" v-on="on">
            {{  tickets.length - ticketsUsed.length }}
          </h5>
        </template>
        <p class="mb-0" style="max-width: 550px">
          Ingressos que não foram validados.
        </p>
      </v-tooltip>
      <p class="mb-0 text-center lh-2">Ingressos não utilizados</p> 
    </v-card>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "EntriesSummary",
  props: {
    tickets: {
      type: Array | Boolean,
      default: () => [],
    },
    party: Object,
    entries: Array,
    loading: Boolean,
    duration: Object,
    ticketsUsed: Array,
    selectedPeriod: Object | null,
  },
  computed: {
    entriesPerMinutes() {
      if (!this.entries.length) return [1, null, null];

      return [
        this.duration.duration.asMinutes(),
        this.duration.start,
        this.duration.end,
      ];
    },

    remainTime() {
      const duration = this.entriesPerMinutes[0];
      const entriesPerMinutes = this.ticketsUsed.length / duration;
      const remainEntries = this.tickets.length - this.ticketsUsed.length;

      const remainTime = remainEntries / entriesPerMinutes;
      const momentDuration = moment.duration(parseInt(remainTime), "minutes");

      return momentDuration.humanize(true);
    },
    ended() {
      var endDate = this.party.endDate;
      if (this.selectedPeriod) {
        endDate = this.selectedPeriod.endDate;
      }
      return moment().isAfter(moment(endDate));
    },
  },
};
</script>

<style>
</style>